import React from "react";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import theme from "@onnit-js/ui/components/themes/light";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import { Img, Picture } from "@onnit-js/ui/components/image";
import ProductImageGrid from "@onnit-js/ui/components/module/ProductImageGrid";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";
import SingleProductBuyBox from "@onnit-js/ui/components/buybox/SingleProductBuyBox";
import Product from "@onnit-js/ui/@types/interfaces/product/BuyBoxProduct";
import { H1, List, ListItem, Sup, Text } from "@onnit-js/ui/components/text";
import LabelContent from "@onnit-js/ui/components/module/LabelContent";
import MultiColumnText from "@onnit-js/ui/components/text/MultiColumnText";
import MediaCollage01 from "@onnit-js/ui/components/module/MediaCollage01";
import MediaCollage02 from "@onnit-js/ui/components/module/MediaCollage02";
import MediaCollage03 from "@onnit-js/ui/components/module/MediaCollage03";
import Embed from "@onnit-js/ui/components/video/Embed";
import ImageContentPortrait from "@onnit-js/ui/components/module/ImageContentPortrait";
import BgImage from "@onnit-js/ui/components/image/BgImage";
import Dash from "@onnit-js/ui/components/icon/Dash";
import BgImageGradient from "@onnit-js/ui/components/module/BgImageGradient";
import MediaHero02 from "@onnit-js/ui/components/module/MediaHero02";
import Video from "@onnit-js/ui/components/video/Video";
import Media from "@onnit-js/ui/components/media";
import LeftLineCTA from "@onnit-js/ui/components/cta/LeftLineCTA";
import { FullDisclaimer } from "@onnit-js/ui/components/trials/disclaimers/Disclaimers";
import { CartProductConfig } from "../../interfaces/CartProductConfig";
import HubSpotEmailCapture from "../../components/common/HubSpotEmailCapture";
import UsageAndDisclaimers from "../../components/common/UsageAndDisclaimers";
import SupplementFacts from "../../components/common/SupplementFacts";
import AddToCartButton from "../../components/common/AddToCartButton";
import SimpleProductPageTop from "../../components/common/layouts/SimpleProductPageTop";
import usePageData from "../../hooks/usePageData";
import IngredientSliders from "../alphabrain/IngredientSliders";

const blackish = "#23343e";
const peach = "#ffaa52";
const orangeish = "#ffB251";

interface TestimonialProps extends BoxProps {
    quote: string;
    starsUrl: string;
    author: string;
}

const Testimonial = ({ starsUrl, quote, author, color = "black", my = 6, ...rest }: TestimonialProps) => {
    const onnitContext = useOnnitContext();
    if (!onnitContext) {
        return null;
    }
    return (
        <Box my={my} {...rest}>
            <Box width="90px" mb={3}>
                <Img lazyLoad={false} src={starsUrl} width={90} height={19} alt="5 of 5 stars rating" />
            </Box>
            <Text as="p" color={color} mb={2} pl={6} dangerouslySetInnerHTML={{ __html: quote }} />
            <Text fontWeight="bold" as="p" color={color} mb={2} pl={6}>
                -{author}
            </Text>
        </Box>
    );
};

const Benefits = ({ benefits, textColor }: { benefits?: any[]; textColor: string }) => {
    if (!benefits) {
        return null;
    }

    return (
        <>
            {benefits && benefits.length > 0 && (
                <Box py={4}>
                    <Text fontSize={6} mb={3} fontWeight="bold">
                        Benefits
                    </Text>
                    <List pl={5}>
                        {benefits.map((text, index) => (
                            <ListItem
                                /* eslint-disable-next-line react/no-array-index-key */
                                key={index}
                                color={textColor}
                                lineHeight={6}
                                dangerouslySetInnerHTML={{ __html: text }}
                            />
                        ))}
                    </List>
                </Box>
            )}
        </>
    );
};

interface CustomProductBoxProps {
    product: Product;
    buttonColor?: string;
    buttonTextColor?: string;
    textColor: string;
    showBenefits?: boolean;
    benefits?: string[];
    tags?: string[];
}

const CustomProductBox = (
    {
        showBenefits = true,
        benefits,
        tags,
        product,
        buttonColor,
        buttonTextColor,
        textColor,
    }: CustomProductBoxProps) => {
    let listItems = benefits;
    if (Array.isArray(tags)) {
        listItems = benefits?.concat(tags);
    }

    return (
        <>
            <SingleProductBuyBox
                mb={4}
                product={product}
                renderInStockView={(product: Product) => (
                    <AddToCartButton
                        onSuccess={() => {
                            window.location.href = "/cart/checkout";
                        }}
                        width={1}
                        size="large"
                        buyBtnText="Get it Free"
                        buttonColor={buttonColor}
                        buttonTextColor={buttonTextColor}
                        configs={[
                            {
                                product_id: product.id,
                                quantity: 1,
                                subscription_interval: null,
                            },
                        ]}
                    />
                )}
            />
            {showBenefits && <Benefits textColor={textColor} benefits={listItems} />}

            <FullDisclaimer msrp={product.price_msrp_raw} trialProductId={product.id} color={textColor} />
        </>
    );
};

const addTrialToCart = (pid: number) => {
    // Push this check to when the user clicks the button
    // because ONNIT.cart is defined after this app bootstraps.
    if (!window.ONNIT || !window.ONNIT.cart) {
        throw new Error("ONNIT.cart is undefined");
    }

    const config: CartProductConfig = {
        product_id: pid,
        quantity: 1,
        subscription_interval: null,
    };

    window.ONNIT.cart
        .addProducts([config])
        .then(() => {
            window.location.href = "/cart/checkout";
        })
        .catch((error) => console.error("Failed to add product to cart.", error));
};

const CustomEmailListSignup = (
    {
        benefits,
        tags,
        product,
        textColor = "grays.5",
    }: {
        benefits?: string[];
        tags?: string[];
        product: Product;
        textColor: string;
    }) => {
    const inStock = product ? product.in_stock : false;

    return (
        <>
            {inStock && (
                <>
                    <Text as="p" my={1} color={textColor}>
                        Enter your email below to get started.
                    </Text>
                    <HubSpotEmailCapture
                        pageName="Alpha BRAIN® Trial"
                        portalId="2252779"
                        formId="30c5b843-5d89-4075-8c19-8e0856508823"
                        buttonLabel="Sign up For Alpha BRAIN® Trial"
                        successFeedbackColor="black"
                        onSuccess={() => addTrialToCart(product.id)}
                    />
                    <Box py={[4, 4, 6]}>
                        <FullDisclaimer msrp={product.price_msrp_raw} trialProductId={product.id} />
                    </Box>
                </>
            )}
            {!inStock && (
                // Show OOS product box instead of email add to cart capture
                (
                    <CustomProductBox
                        benefits={benefits}
                        tags={tags}
                        product={product}
                        textColor="black"
                        buttonColor="black"
                        buttonTextColor="white"
                    />
                )
            )}
        </>
    );
};

interface CustomAddToBagSectionProps {
    imgUrl?: string;
    altText?: string;
    showBenefits?: boolean;
    buttonColor?: string;
    buttonTextColor?: string;
    headlineColor?: string;
    textColor?: string;
}

const CustomAddToBagSection: React.FC<React.PropsWithChildren<React.PropsWithChildren<CustomAddToBagSectionProps>>> = (
    {
        children,
        imgUrl,
        altText,
        showBenefits = true,
        buttonColor,
        buttonTextColor,
        headlineColor = "black",
        textColor = "grays.5",

        ...rest
    }) => {
    const onnitContext = useOnnitContext();
    const { pageData } = usePageData();
    const isSignupPage = window.location.href.indexOf("get-alpha-brain-trial") > -1;

    if (!pageData || !onnitContext) {
        return null;
    }

    return (
        <>
            <Box {...rest}>
                {imgUrl && (
                    <TwelveColumn py={[6, 6, 8, 10]}>
                        {imgUrl && (
                            <Box gridColumn={["1 / -1", "1 / -1", "1 / -1", "2 / span 6"]} px={4}>
                                <Img placeholder={[688, 380]} alt={altText} src={imgUrl} />
                            </Box>
                        )}
                        <Box gridColumn={["1 / -1", "1 / -1", "1 / -1", "8 / span 4"]} px={[4, 4, 6]}>
                            {children && (
                                <Text
                                    color={headlineColor}
                                    fontStyle="italic"
                                    fontWeight="bold"
                                    lineHeight={1}
                                    fontSize={[6, 8]}
                                    pt={3}
                                    mb={6}
                                >
                                    {children}
                                </Text>
                            )}

                            {isSignupPage && (
                                <Box px={[4, 4, 0]}>
                                    <CustomEmailListSignup
                                        benefits={pageData.benefits}
                                        tags={pageData.tags}
                                        textColor={textColor}
                                        product={pageData.products[0]}
                                    />
                                </Box>
                            )}

                            {!isSignupPage && (
                                <Box py={4}>
                                    <CustomProductBox
                                        showBenefits={showBenefits}
                                        benefits={pageData.benefits}
                                        tags={pageData.tags}
                                        product={pageData.products[0]}
                                        buttonColor={buttonColor}
                                        buttonTextColor={buttonTextColor}
                                        textColor={textColor}
                                    />
                                </Box>
                            )}
                        </Box>
                    </TwelveColumn>
                )}

                {!imgUrl && (
                    <TwelveColumn>
                        <Box gridColumn={["1 / -1"]}>
                            {children && (
                                <Text
                                    color={headlineColor}
                                    fontStyle="italic"
                                    fontWeight="bold"
                                    lineHeight={1}
                                    fontSize={[6, 8]}
                                    mb={6}
                                >
                                    {children}
                                </Text>
                            )}

                            {isSignupPage && (
                                <Box>
                                    <CustomEmailListSignup
                                        benefits={pageData.benefits}
                                        tags={pageData.tags}
                                        textColor={textColor}
                                        product={pageData.products[0]}
                                    />
                                </Box>
                            )}
                            {!isSignupPage && (
                                <CustomProductBox
                                    showBenefits={showBenefits}
                                    benefits={pageData.benefits}
                                    tags={pageData.tags}
                                    product={pageData.products[0]}
                                    buttonColor={buttonColor}
                                    buttonTextColor={buttonTextColor}
                                    textColor={textColor}
                                />
                            )}
                        </Box>
                    </TwelveColumn>
                )}
            </Box>
        </>
    );
};

export default function Page() {
    const onnitContext = useOnnitContext();
    const { pageData } = usePageData();

    if (!pageData || !onnitContext) {
        return null;
    }

    const IMG_PARAMS = "?q=40&fm=pjpg&auto=compress,format";
    const IMG_URL = `${onnitContext.url.cdn.static_images}/product-page/alpha-brain-30-free-trial`;
    const SCDN_URL = `${onnitContext.url.cdn.static}/images/product-page/alpha-brain-30-free-trial`;

    return (
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyle />
                <H1 className="sr-only">Alpha BRAIN<Sup variant="reg" />{" "}Trial</H1>
                <SimpleProductPageTop
                    stickyHeaderBtnText="Try Now"
                    buyAreaChild={(
                        <>
                            <CustomAddToBagSection />
                        </>
                    )}
                >
                    <ProductImageGrid>
                        <Picture
                            lazyLoad={false}
                            width={944}
                            height={494}
                            src={[
                                `${IMG_URL}/alpha-brain-bottle.jpg${IMG_PARAMS}`,
                                `${IMG_URL}/alpha-brain-bottle--wide.jpg${IMG_PARAMS}`,
                            ]}
                            alt="Single bottle of Alpha BRAIN"
                        />

                        <Picture
                            lazyLoad={false}
                            width={460}
                            height={460}
                            src={[`${IMG_URL}/bmx-girl-taking-alpha-brain.jpg${IMG_PARAMS}`]}
                            alt="BMX Athlete takes Alpha BRAIN"
                        />

                        <Picture
                            lazyLoad={false}
                            width={460}
                            height={460}
                            src={[`${IMG_URL}/cutting-board-ingredients--square.jpg${IMG_PARAMS}`]}
                            alt="Alpha BRAIN® capsules on cutting board"
                        />

                        <Picture
                            lazyLoad={false}
                            width={944}
                            height={494}
                            src={[
                                `${IMG_URL}/bmx-girl-sunset--square.jpg${IMG_PARAMS}`,
                                `${IMG_URL}/bmx-girl-sunset.jpg${IMG_PARAMS}`,
                            ]}
                            alt="Girl on BMX bike in the sunset"
                        />
                    </ProductImageGrid>
                </SimpleProductPageTop>

                <div className="hide-ie">
                    <TwelveColumn display="grid" mt={[8, 8, 10]} mb={6}>
                        <Box gridColumn={["1 / -1", "2 / -1", "3 / span 9"]} px={4}>
                            <Text
                                color={blackish}
                                typeStyle="headlineHero01"
                                fontSize={[7, 7, 10, 12]}
                                textTransform="uppercase"
                            >
                                Razor Sharp
                                <br />
                                Clarity & Laser
                                <br />
                                Focus Support
                                <br />
                                in a Bottle
                                <Sup />
                            </Text>
                        </Box>
                    </TwelveColumn>

                    <Box bg={peach} pt={[6, 6, 8]} pb={[6, "10%"]}>
                        <LabelContent
                            label={(
                                <Text typeStyle="copy05" color={blackish}>
                                    Alpha BRAIN
                                    <Sup variant="reg" />
                                </Text>
                            )}
                            content={(
                                <>
                                    <Text as="p" typeStyle="copy02" fontWeight="regular" mb={4}>
                                        It was so frustrating.
                                    </Text>
                                    <Text as="p" typeStyle="copy02" fontWeight="regular" mb={4}>
                                        Before I founded Onnit, I worked several jobs at once. I had no choice but to
                                        multitask, and if I didn't stay on top of everything, I risked losing clients. I
                                        had so many demands to meet, you’d think I would have had no time to think about
                                        anything else, and yet my attention would easily drift away from what I was
                                        working on (usually to social media). It was almost like my mind was playing
                                        tricks on me. When I needed to think fast to solve problems, I seemed to slow
                                        down even further. I was occasionally forgetting names of people I had just met
                                        and tasks I had to accomplish.
                                    </Text>
                                </>
                            )}
                        />
                    </Box>

                    <Box bg="white" pt={[6, 6, 8]}>
                        <MediaCollage01
                            mt={["-18%", "-16%", "-16%", "-12%"]}
                            item1={(
                                <Img
                                    src={`${IMG_URL}/Collage-1-Image-1.jpg${IMG_PARAMS}`}
                                    alt="Girl drinking from water bottle"
                                    width={448}
                                    height={692}
                                />
                            )}
                            item2={(
                                <Img
                                    src={`${IMG_URL}/Collage-1-Image-2.jpg${IMG_PARAMS}`}
                                    alt="Bottle of Alpha BRAIN"
                                    width={448}
                                    height={692}
                                />
                            )}
                            item3={(
                                <Img
                                    src={`${IMG_URL}/Collage-1-Image-3.jpg${IMG_PARAMS}`}
                                    alt="Girl on BMX bike"
                                    width={828}
                                    height={1280}
                                />
                            )}
                        />
                        <LabelContent
                            mt={[4, 6, 8]}
                            content={(
                                <MultiColumnText>
                                    <Text as="p" typeStyle="copy02" fontWeight="bold" mb={4} display="inline-block">
                                        Worst of all, my brain sometimes felt sluggish when I most needed to be at the
                                        top of my game.
                                    </Text>
                                    <Text as="p" typeStyle="copy02" fontWeight="regular" mb={4}>
                                        I tried everything to help my focus and alertness, but at some point, nothing
                                        seemed to work. I got to where I was drinking a few cups of coffee daily, and if
                                        I drank too much, I wouldn’t be able to sleep, which only made things worse. I’d
                                        be "twired" (tired but wired) all night.
                                    </Text>
                                    <Text as="p" typeStyle="copy02" fontWeight="regular" mb={4}>
                                        I tried willpower—disciplining myself to stare at my computer screen until I got
                                        all my work done. But it just made me rush things.
                                    </Text>
                                </MultiColumnText>
                            )}
                        />

                        <ImageContentPortrait
                            reverse
                            image={(
                                <Img
                                    src={`${IMG_URL}/gamer-girl.jpg${IMG_PARAMS}`}
                                    alt="Girl playing video games"
                                    width={926}
                                    height={1200}
                                />
                            )}
                            content={(
                                <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                    <Text as="p" typeStyle="copy02" fontWeight="regular" mb={4} display="inline-block">
                                        <Text as="span" typeStyle="copy02" fontWeight="bold" display="inline">
                                            Then I found out about nootropics—compounds that help support certain
                                            cognitive functions, including memory, attention, and focus.
                                            <Sup variant="dagger" />
                                        </Text>{" "}
                                        I dove into the research, and called on research scientists to help me formulate
                                        something that could help me get my brain online.
                                    </Text>
                                    <Text as="p" typeStyle="copy02" fontWeight="bold" mb={4}>
                                        What we discovered blew us away.
                                    </Text>
                                    <Text as="p" typeStyle="copy02" fontWeight="regular" mb={4}>
                                        The formula that worked best for my colleagues and I became what is today the
                                        one of the most renowned nootropic supplements in the world, with more than
                                        three million bottles sold to show for it.
                                    </Text>
                                    <Text as="p" typeStyle="copy02" fontWeight="regular" mb={4}>
                                        It became Alpha BRAIN
                                        <Sup variant="reg" />.
                                    </Text>

                                    <Box bg="white" mt={[4, 6, 8]}>
                                        <CustomAddToBagSection
                                            showBenefits={false}
                                            textColor="black"
                                            headlineColor="black"
                                            buttonColor="black"
                                            buttonTextColor="white"
                                        />
                                    </Box>
                                </Box>
                            )}
                        />
                    </Box>
                    <Box mt={[6, 6, 6, 8]} backgroundColor="black">
                        <BgImageGradient
                            maxWidth={1400}
                            bgColor="black"
                            mx="auto"
                            backgroundSize="cover"
                            backgroundPosition={["left top"]}
                            backgroundRepeat="none"
                            bgSrc={`${IMG_URL}/aubrey--bg.jpg${IMG_PARAMS}`}
                        >
                            <Box>
                                <Box minHeight={[350, 500, 600, 800]} />
                            </Box>
                        </BgImageGradient>
                        <TwelveColumn>
                            <Box
                                gridColumn={["1 / -1", "1/-1", "2 /span 10", "3 / span 8"]}
                                px={[6, 6, 0]}
                                pb={[4, 6, 8]}
                            >
                                <Text
                                    as="p"
                                    typeStyle="copy02"
                                    color={orangeish}
                                    fontWeight="regular"
                                    mb={4}
                                    display="inline-block"
                                >
                                    <Text
                                        as="span"
                                        color={orangeish}
                                        typeStyle="copy02"
                                        fontWeight="bold"
                                        display="inline"
                                    >
                                        Alpha BRAIN
                                        <Sup variant="reg" /> is caffeine and gluten free.
                                    </Text>
                                </Text>
                                <Text as="p" typeStyle="copy02" color="white" fontWeight="regular" mb={4}>
                                    When you take Alpha BRAIN
                                    <Sup variant="reg" />, there are subtle but profound changes in your ability to
                                    support thinking more clearly and focusing—all of which can help to transform your
                                    life. Before you know it, you’ll have an added boost to help accomplish tasks you
                                    have been putting off. You’ll also be able to think more clearly under occasional
                                    stress. Your "to-do" lists will get checked off.
                                    <Sup />
                                </Text>
                                <Text
                                    as="p"
                                    typeStyle="copy02"
                                    color="white"
                                    fontWeight="regular"
                                    mb={4}
                                    display="inline-block"
                                >
                                    You have to try it. But I don’t want you to buy Alpha BRAIN
                                    <Sup variant="reg" />, until you believe in it as strongly as I do.{" "}
                                    <Text as="span" color="white" typeStyle="copy02" fontWeight="bold" display="inline">
                                        That’s why, for a limited time, I’m giving you a chance to try Alpha BRAIN
                                        <Sup variant="reg" /> for FREE. Just pay for shipping and you’ll get a 15-day
                                        supply at no extra cost.
                                    </Text>
                                </Text>
                                <Text as="p" typeStyle="copy02" color={orangeish} fontWeight="bold" mb={4}>
                                    Try it. I&rsquo;m confident you&rsquo;ll love it.
                                </Text>
                                <Text
                                    as="p"
                                    typeStyle="copy02"
                                    color={orangeish}
                                    fontStyle="italic"
                                    fontWeight="bold"
                                    pt={4}
                                    mb={4}
                                    display="inline-block"
                                >
                                    &mdash; Aubrey Marcus
                                    <br />
                                    <Text
                                        as="span"
                                        typeStyle="copy02"
                                        color={orangeish}
                                        fontStyle="italic"
                                        fontSize={[2, 3, 4]}
                                    >
                                        Chairman and Founder of Onnit
                                    </Text>
                                </Text>
                            </Box>
                        </TwelveColumn>
                    </Box>

                    <Box bg="white">
                        <CustomAddToBagSection
                            imgUrl={`${IMG_URL}/alpha-brain-trial-cta--wide.jpg${IMG_PARAMS}`}
                            altText="Bottle of Alpha BRAIN® on cutting board"
                            textColor="black"
                            headlineColor="black"
                            buttonColor="black"
                            buttonTextColor="white"
                        >
                            Get Your Free
                            <br />
                            Alpha BRAIN
                            <Sup variant="reg" />
                        </CustomAddToBagSection>
                    </Box>

                    <TwelveColumn mb={[4, 4, 6]}>
                        <Box gridColumn={["1 / -1", "1 / -1", "2 / -1", "3 / span 9"]} px={4}>
                            <Text
                                color={blackish}
                                typeStyle="headlineHero01"
                                fontSize={[7, 7, 10, 12]}
                                textTransform="uppercase"
                            >
                                What People
                                <br />
                                Are Saying
                            </Text>
                        </Box>

                        <Box gridColumn={["1 / -1", "1 / -1", "1/-1", "4 /span 6"]}>
                            <Embed
                                title="Alpha BRAIN® Product Video"
                                src="https://player.vimeo.com/video/859816921"
                                my={[6, 6, 7]}
                            />
                        </Box>
                    </TwelveColumn>

                    <Box py={[4, 4, 6, 8]}>
                        <BgImage
                            pt={[2, 2, 2, 4]}
                            minHeight={[600, 600, 800, 1000]}
                            backgroundPosition={["center top"]}
                            backgroundColor="black"
                            src={`${IMG_URL}/alpha-brain-benefits--bg.jpg${IMG_PARAMS}`}
                        >
                            <Box py={[4, 4, 6, 8]}>
                                <LabelContent
                                    pt={[6, 6, 4, 0]}
                                    reverse
                                    withDash={false}
                                    label={(
                                        <Dash
                                            mx={["", "", "", "auto"]}
                                            color="white"
                                            mb={[3, 3, 5]}
                                            width={["80px", "80px", "100px", "130px"]}
                                        />
                                    )}
                                    content={(
                                        <Text
                                            typeStyle="headlineHero01"
                                            fontSize={[7, 7, 10, 12]}
                                            color="white"
                                            textTransform="uppercase"
                                            mb={4}
                                        >
                                            Alpha BRAIN
                                            <Sup variant="reg" fontSize={[4, 6]} />
                                            <br />
                                            Benefits:
                                        </Text>
                                    )}
                                />
                                <TwelveColumn>
                                    <Box gridColumn={["1 / -1", "1/-1", "1/-1", "2 /span 6"]}>
                                        <List ml={[4, 4, 4, 2]} pl={[6, 6, 6, 4]}>
                                            {[
                                                'Promotes focus<span class="oui-sup">&dagger;</span>',
                                                'Supports memory<span class="oui-sup">&dagger;</span>',
                                                'Helps with flow state<br />(the feeling of being &ldquo;in the zone&rdquo;)<span class="oui-sup">&dagger;</span>',
                                                "Caffeine free",
                                                "Gluten free",
                                            ].map((text, index) => (
                                                <ListItem
                                                    /* eslint-disable-next-line react/no-array-index-key */
                                                    key={index}
                                                    my={[3, 6]}
                                                    color="white"
                                                    typeStyle="copyDisplay02"
                                                    fontSize={[2, 4, 5, 6]}
                                                    dangerouslySetInnerHTML={{ __html: text }}
                                                />
                                            ))}
                                        </List>
                                    </Box>
                                </TwelveColumn>
                            </Box>
                        </BgImage>
                        <Box mt={["-28%", "-16%", "-18%", "-14%"]}>
                            <MediaCollage03
                                item1={(
                                    <Img
                                        src={`${IMG_URL}/Collage-2-gamers.jpg${IMG_PARAMS}`}
                                        alt="Gamers surrounding TV"
                                        width={1168}
                                        height={656}
                                    />
                                )}
                                item2={(
                                    <Img
                                        src={`${IMG_URL}/Collage-2-controller.jpg${IMG_PARAMS}`}
                                        alt="Hands holding gaming controller"
                                        width={448}
                                        height={448}
                                    />
                                )}
                                item3={(
                                    <Img
                                        src={`${IMG_URL}/Collage-2-alpha-brain-bottle.jpg${IMG_PARAMS}`}
                                        alt="Single bottle of ALpha BRAIN"
                                        width={688}
                                        height={388}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                    <Box backgroundColor={peach}>
                        <MediaHero02
                            pt={[5, 5, 5, 10]}
                            media={(
                                <Media query="(min-width: 768px)">
                                    {(matches) => (matches ? (
                                        <Box mt={["-39%", "-39%", "-42%"]} pl={["10%", "10%", 0]}>
                                            <Video
                                                key={2}
                                                autoPlay
                                                loop
                                                muted
                                                playsInline
                                                placeholder={[1440, 810]}
                                                title="Girl riding BMX Bike"
                                            >
                                                <source src={`${SCDN_URL}/bmx-loop.mp4`} type="video/mp4" />
                                            </Video>
                                        </Box>
                                    ) : (
                                        <Box mt={[0, "-39%"]} pl={[0, "10%"]}>
                                            <Img
                                                src={`${IMG_URL}/bmx-still.jpg${IMG_PARAMS}`}
                                                alt="Woman riding BMX bike"
                                                width={828}
                                                height={228}
                                            />
                                        </Box>
                                    ))}
                                </Media>
                            )}
                            headline={(
                                <Box>
                                    <Text
                                        color={blackish}
                                        typeStyle="headlineHero01"
                                        fontSize={["32px", "76px", "76px", "112px", "132px"]}
                                    >
                                        Get Your
                                        <br />
                                        Free
                                        <br />
                                        Bottle
                                    </Text>
                                </Box>
                            )}
                        />
                        <CustomAddToBagSection
                            imgUrl={`${IMG_URL}/alpha-brain-trial-cta--wide.jpg${IMG_PARAMS}`}
                            altText="Bottle of Alpha BRAIN® on cutting board"
                            textColor="black"
                            headlineColor="black"
                            buttonColor="black"
                            buttonTextColor="white"
                        >
                            Get Your Free
                            <br />
                            Alpha BRAIN
                            <Sup variant="reg" />
                        </CustomAddToBagSection>
                    </Box>

                    <Box backgroundColor={blackish}>
                        <BgImage src={`${IMG_URL}/onnit-logo-pattern--bg.png${IMG_PARAMS}`}>
                            <Box py={[4, 6, 8, 10]}>
                                <TwelveColumn display="grid" mb={6}>
                                    <Box gridColumn={["1 / -1", "1 / -1", "2 / -1", "3 / span 9"]} px={4}>
                                        <Text
                                            color={orangeish}
                                            pb={[4, 6]}
                                            typeStyle="headlineHero01"
                                            fontSize={[7, 7, 10, 12]}
                                            textTransform="uppercase"
                                        >
                                            Recommended
                                            <br />
                                            By the Best
                                        </Text>
                                        <Text as="p" typeStyle="copy02" color="white" mb={4}>
                                            Alpha BRAIN
                                            <Sup variant="reg" /> is used by professional athletes, successful CEOs, and
                                            world-famous entertainers—including members of our sponsored pro team.
                                        </Text>
                                    </Box>
                                </TwelveColumn>

                                <ImageContentPortrait
                                    image={(
                                        <Img
                                            src={`${IMG_URL}/HannahEden.jpg${IMG_PARAMS}`}
                                            alt="Hannah Eden"
                                            width={928}
                                            height={928}
                                        />
                                    )}
                                    content={(
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            height="100%"
                                            justifyContent="center"
                                        >
                                            <Text as="p" typeStyle="copy02" color="white">
                                                &ldquo;There’s no better way to get your mind firing on all cylinders
                                                than with this nootropic! I won’t start a day in the office without it.
                                                <Sup variant="dagger" />
                                                &rdquo;
                                            </Text>
                                            <LeftLineCTA
                                                color={peach}
                                                pt={4}
                                                textAlign={["left", "left", "right"]}
                                                justifyContent={["flex-end"]}
                                            >
                                                Hannah Eden
                                                <Text color="peach">Onnit Partner</Text>
                                            </LeftLineCTA>
                                        </Box>
                                    )}
                                />

                                <ImageContentPortrait
                                    reverse
                                    image={(
                                        <Img
                                            src={`${IMG_URL}/NeenWilliams.jpg${IMG_PARAMS}`}
                                            alt="Need Williams"
                                            width={928}
                                            height={928}
                                        />
                                    )}
                                    content={(
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            height="100%"
                                            justifyContent="center"
                                        >
                                            <Text as="p" typeStyle="copy02" color="white">
                                                &ldquo;I use Onnit Alpha BRAIN
                                                <Sup variant="reg" /> daily. Personally, I feel clearer and more focused
                                                on days that I take it.
                                                <Sup variant="dagger" />
                                                &rdquo;
                                            </Text>
                                            <LeftLineCTA
                                                color={peach}
                                                pt={4}
                                                textAlign={["left", "left", "right"]}
                                                justifyContent={["flex-end"]}
                                            >
                                                Neen Williams
                                                <Text color="peach">Onnit Partner</Text>
                                            </LeftLineCTA>
                                        </Box>
                                    )}
                                />
                            </Box>
                        </BgImage>
                    </Box>

                    <Box py={[4, 6, 8, 10]}>
                        <ImageContentPortrait
                            image={(
                                <Img
                                    src={`${IMG_URL}/gamer-controller2.jpg${IMG_PARAMS}`}
                                    alt="Hands holding gaming controller"
                                    width={926}
                                    height={1200}
                                />
                            )}
                            reverse
                            content={(
                                <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                    <Text typeStyle="copyDisplay02" mb={[4, 4, 6]}>
                                        How Alpha BRAIN
                                        <Sup variant="reg" /> Works
                                    </Text>
                                    <Text as="p" typeStyle="copy02" mb={4}>
                                        If you want to help your brain think more clearly, you need to concentrate on
                                        two things: promoting the production of alpha waves in the brain and supporting
                                        acetylcholine levels—a key neurotransmitter.
                                        <Sup />︎ We formulated Alpha BRAIN
                                        <Sup variant="reg" /> with both goals in mind.
                                    </Text>
                                    <Text as="p" typeStyle="copy02" mb={4}>
                                        With Onnit’s distinct ingredient blends, Alpha BRAIN
                                        <Sup variant="reg" /> helps build an environment in which the brain can operate
                                        on all cylinders, supporting mental clarity.
                                        <Sup variant="dagger" />
                                    </Text>
                                </Box>
                            )}
                        />
                    </Box>

                    <Box backgroundColor={peach} py={[6, 8, 8, 10]} px={4}>
                        <Box maxWidth="960px" mx="auto">
                            <Text
                                fontWeight="black"
                                lineHeight={0}
                                textTransform="uppercase"
                                color={blackish}
                                fontSize={[7, 7, 10, 12]}
                                mb={[6, 6, 8]}
                            >
                                What customers are saying about Alpha BRAIN
                                <Sup variant="reg" />
                            </Text>
                            <Testimonial
                                starsUrl={`${IMG_URL}/5-stars.svg${IMG_PARAMS}`}
                                quote="Fantastic product. I have clearer thoughts and can remember things much easier. I highly recommend it."
                                author="Kevin"
                            />
                            <Testimonial
                                starsUrl={`${IMG_URL}/5-stars.svg${IMG_PARAMS}`}
                                quote='Amazing! I get so much done with Alpha BRAIN<span className="oui-sup">&reg;</span>! I love that it is not stimulant based but just great brain food. I am placing my second order and have told so many friends they have to try it.'
                                author="Hardy"
                            />
                            <Testimonial
                                starsUrl={`${IMG_URL}/5-stars.svg${IMG_PARAMS}`}
                                quote='Alpha BRAIN<span class="oui-sup">&reg;</span> is a wonderful aid in my memory &amp; focus. Highly recommended!'
                                author="Edward"
                            />
                        </Box>
                    </Box>

                    <IngredientSliders
                        imageUrl={`${onnitContext.url.cdn.static_images}/product-page/alpha-brain/refresh-full`}
                    />

                    <Box py={[6, 6, 8]}>
                        <MediaCollage02
                            item1={(
                                <Img
                                    src={`${IMG_URL}/Collage-3-Image-1.jpg${IMG_PARAMS}`}
                                    alt="Man getting haircut"
                                    width={448}
                                    height={270}
                                />
                            )}
                            item2={(
                                <Img
                                    src={`${IMG_URL}/Collage-3-Image-2.jpg${IMG_PARAMS}`}
                                    alt="Single bottle of Alpha BRAIN® in barber shop"
                                    width={688}
                                    height={688}
                                />
                            )}
                            item3={(
                                <Img
                                    src={`${IMG_URL}/Collage-3-Image-3.jpg${IMG_PARAMS}`}
                                    alt="Barber combing man's hair"
                                    width={1168}
                                    height={876}
                                />
                            )}
                        />

                        <TwelveColumn py={[6, 6, 8]}>
                            <Box gridColumn={["1 / -1", "1 / -1", "1 / -1", "2 / -1", "3 / span 9"]} px={4}>
                                <Text
                                    mb={6}
                                    color={blackish}
                                    typeStyle="headlineHero01"
                                    fontSize={[7, 7, 10, 12]}
                                    textTransform="uppercase"
                                >
                                    Is Alpha BRAIN
                                    <Sup variant="reg" />
                                    <br />
                                    Worth the Money?
                                </Text>
                                <MultiColumnText>
                                    <Text as="p" typeStyle="copy02" fontWeight="regular" mb={4} display="inline-block">
                                        We believe that using Alpha BRAIN
                                        <Sup variant="reg" /> can fire up your brain at a higher RPM level, but we don’t
                                        expect you to take us at our word. We want to earn your trust by giving you your
                                        first sample free. If you give Alpha BRAIN
                                        <Sup variant="reg" /> a fair shot and decide it’s not for you, you don’t owe us
                                        a thing.
                                    </Text>
                                    <Text as="p" typeStyle="copy02" fontWeight="regular" mb={4}>
                                        But, if you decide you love the product, and you want to start buying it going
                                        forward, we don’t want the price to prevent you from seeing great results.
                                    </Text>
                                    <Text as="p" typeStyle="copy02" fontWeight="regular" mb={4}>
                                        &ldquo;I looked at your competitor, and they&rsquo;re offering another
                                        supplement for cheaper!&rdquo;
                                    </Text>
                                    <Text as="p" typeStyle="copy02" fontWeight="regular" mb={4}>
                                        We hear this a lot, and we’re well aware that Alpha BRAIN
                                        <Sup variant="reg" /> is not the cheapest nootropic supplement on the market.
                                        For people who’ve never tried a nootropic, or any supplement for that matter,
                                        the price might seem high.
                                    </Text>
                                    <Text as="p" typeStyle="copy02" fontWeight="regular" mb={4}>
                                        But is it really?
                                    </Text>
                                    <Text as="p" typeStyle="copy02" fontWeight="regular" mb={4}>
                                        When you crunch the numbers, you’ll see that Alpha BRAIN
                                        <Sup variant="reg" /> is actually a bargain. Our 30-count bottle provides a
                                        15-day supply, assuming you take it every day.
                                    </Text>
                                </MultiColumnText>
                            </Box>
                        </TwelveColumn>

                        <Box bg={peach}>
                            <CustomAddToBagSection
                                imgUrl={`${IMG_URL}/alpha-brain-trial-cta--wide.jpg${IMG_PARAMS}`}
                                altText="Bottle of Alpha BRAIN® on cutting board"
                                textColor="black"
                                headlineColor="black"
                                buttonColor="black"
                                buttonTextColor="white"
                            >
                                Get Your Free
                                <br />
                                Alpha BRAIN
                                <Sup variant="reg" />
                            </CustomAddToBagSection>
                        </Box>
                    </Box>
                </div>

                <UsageAndDisclaimers supplementFacts={<SupplementFacts enableZoom />} supplementWarning>
                    <Text fontSize={0} color="grays.4" my={4}>
                        <Sup>*</Sup>Offer valid for qualified customers only. Domestic Orders Only.
                    </Text>
                    <Text
                        fontSize={0}
                        color="grays.4"
                        target="_blank"
                        href="/terms-and-conditions/subscription/"
                        as="a"
                    >
                        Read the Terms and Conditions here.
                    </Text>
                </UsageAndDisclaimers>
            </>
        </ThemeProvider>
    );
}
